import { academies } from "./academies";

export const defaults = {

    version: "1.56.2", // TODO: Also update in "package.json" (Consider only using one of these values) [search  and replace recommended!]

    //////////////////////
    // CONFIG: SET DEFAULT ACADEMY
    //
    platform: academies.dayInALife, // CONFIG: This is the main thing you need to confirm before deploying code

    // //////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////
    // //////////////////////////////////////////////////////////////////////////////////////////////
    // CONTINUED, THE REST OF THE CONSTANTS
    // WARNING: this is not a secure way of doing it, use security rules instead
    superAdmins: [
        'ugWavqSLcOReqV9KpVcg0Vpz4k53', // igor
        'l43FCPJgcc9srUGudXri', // slava
    ],
    images: {
        avatar: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_avatars%2Fdefault_male.svg?alt=media&token=bd0374b4-cda2-46c7-998d-cdca6cfd8b40',
        avatarFemale: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_avatars%2Fdefault_female.svg?alt=media&token=3076b3e8-69ad-449f-b9e2-cffbcf95aa48',
        solution: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/solutions%2Fsolution_empty.png?alt=media&token=9f3c6684-0510-4ad7-b094-ecd6ff8289ca',
        biometric: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/solutions%2Fsolution_empty.png?alt=media&token=9f3c6684-0510-4ad7-b094-ecd6ff8289ca', // TODO: Duplicate of the solutions image above
        day: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/backgrounds%2FAfter_Noon%403x.jpg?alt=media&token=bfb661a6-367d-4564-9665-8201d1813914',
        night: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/backgrounds%2FNight%403x.jpg?alt=media&token=7c72064a-4371-4b4a-a728-58b444caac20',
        audio: '/assets/activities/audio.jpeg',
        logo: 'https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2Fm88GC21iNWMmN3EWXxzrnl63MQr1%2F1730955841704_LOGO_Purple.png?alt=media&token=2f077f98-ab40-43ba-a8d8-389f86c2b870',
        logoDark: 'https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2Fm88GC21iNWMmN3EWXxzrnl63MQr1%2F1730785183208_DIAL-LOGO.png?alt=media&token=aa784435-9a6d-4401-a057-70abdac7f52b',
        featuredImage: 'https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2F_owner%2F1732487530315_pawel-czerwinski-GFqo8rVmH30-unsplash.jpg?alt=media&token=987e1dc3-00e5-4e0e-9062-62c14272688e', // pink blue color round shapes
        featuredImageDarkRedBlueCurves: 'https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2F_owner%2F1732487834262_pawel-czerwinski-sQag8O3j2bc-unsplash.jpg?alt=media&token=0d744678-602f-4e1d-99f3-2707ae9adf36', // red blue dark techno
        featuredImageLeaves: 'https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2FdHs5EPkQBZaVsGhgfpAyaYzM9Xl2%2F1731363681993_abstract-green-leaf-texture-tropical-leaf-nature-background_483511-4344.jpg.webp?alt=media&token=9a1b899e-dfc2-47c5-9ce7-18e5ede4242d',
        featuredImageGarden: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/userMedia%2Fl43FCPJgcc9srUGudXri%2F1643945954538__111978839_5_carole_drake_304860.jpeg?alt=media&token=b602346e-437d-4e26-a2bb-1ad91791bf3f',
        introductionImage: 'https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2FdHs5EPkQBZaVsGhgfpAyaYzM9Xl2%2F1731363701406_from-dark-plants_23-2147829263.jpg.webp?alt=media&token=a9d2552c-0466-4037-9f84-d844d64cd1ea',
        easyToFollowImage: 'https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2F_owner%2F1731376449946_DALL%C2%B7E%202024-11-11%2017.46.38%20-%20A%20high-resolution%20illustration%20for%20Day%20In%20A%20Life%20platform%20focused%20on%20holistic%20health%20and%20wellness%2C%20shown%20from%20a%203_4%20perspective.%20Include%20diverse%20users.webp?alt=media&token=e53b56af-88a2-44f3-a341-355ebcb3fae3',
        coachAvatar: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_avatars%2Fdefault_female.svg?alt=media&token=3076b3e8-69ad-449f-b9e2-cffbcf95aa48',
        bannerImage: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_sbc%2FbannerBg.webp?alt=media&token=69107751-76f9-4f63-8d4f-32ba55a0e84a',
        closingImage: 'https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2FdHs5EPkQBZaVsGhgfpAyaYzM9Xl2%2F1731363750288_tropical-palm-leaves-pattern-background-green-monstera-tree-foliage-decoration-design-plant-with-exotic-leaf-closeup_90220-1135.jpg.webp?alt=media&token=effebf7c-55f0-457b-a2a2-86451d54bd6e',
    },
    imagePresets: [
        // tropical leaf background
        "https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2FdHs5EPkQBZaVsGhgfpAyaYzM9Xl2%2F1731363681993_abstract-green-leaf-texture-tropical-leaf-nature-background_483511-4344.jpg.webp?alt=media&token=9a1b899e-dfc2-47c5-9ce7-18e5ede4242d",

        // garden (love myself today)
        "https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/userMedia%2Fl43FCPJgcc9srUGudXri%2F1643945954538__111978839_5_carole_drake_304860.jpeg?alt=media&token=b602346e-437d-4e26-a2bb-1ad91791bf3f",

        // hands reaching
        "https://images.unsplash.com/photo-1541976844346-f18aeac57b06?q=80&w=2448&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",

        // tropical leaf background broad leaves
        "https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2FdHs5EPkQBZaVsGhgfpAyaYzM9Xl2%2F1731363750288_tropical-palm-leaves-pattern-background-green-monstera-tree-foliage-decoration-design-plant-with-exotic-leaf-closeup_90220-1135.jpg.webp?alt=media&token=effebf7c-55f0-457b-a2a2-86451d54bd6e",

        // tropical leaf background bush
        "https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2FdHs5EPkQBZaVsGhgfpAyaYzM9Xl2%2F1731363701406_from-dark-plants_23-2147829263.jpg.webp?alt=media&token=a9d2552c-0466-4037-9f84-d844d64cd1ea",

        // mountain
        "https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2F_owner%2F1732477675533_martin-masson-BkAEKU26osY-unsplash.jpg?alt=media&token=3b04d05c-fb61-48cb-ad50-f917095334ed",

        // light shapes
        "https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2F_owner%2F1732477749827_pawel-czerwinski-Jv4sW9RJyGg-unsplash640x960.jpg?alt=media&token=081b515d-416b-4bfe-bd23-bb1157b05cec",

        // rusty car
        "https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2F_owner%2F1732477848093_raymond-petrik-EuOqsA5ByEk-unsplash.jpg?alt=media&token=23f2eb65-5db2-4410-ac4f-0eb5d48e4c2a",

        // broken stone on dark sand
        "https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2F_owner%2F1732478020592_evie-s-Ucty8rpMwbM-unsplash.jpg?alt=media&token=02f8215f-70e2-45bd-9199-ba67cf6150fe",

        // cubic walk
        "https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2F_owner%2F1732478058503_julian-rosner-0GsqLlGk_EI-unsplash.jpg?alt=media&token=a417bfbf-3498-48db-ba8d-eb32700d3502",

        // mystical forest shrouded in fog
        "https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2F_owner%2F1732479133057_philipp-upR8raSXvwg-unsplash%20(1).jpg?alt=media&token=1db30757-dae4-4752-92c2-e8b9d02cc473",

        // pink blue color round shapes
        "https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2F_owner%2F1732487530315_pawel-czerwinski-GFqo8rVmH30-unsplash.jpg?alt=media&token=987e1dc3-00e5-4e0e-9062-62c14272688e",

        // dark blue red techno lines
        "https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2F_owner%2F1732487834262_pawel-czerwinski-sQag8O3j2bc-unsplash.jpg?alt=media&token=0d744678-602f-4e1d-99f3-2707ae9adf36",
        
        // mint smooth waves
        "https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2F_owner%2F1732487936869_pawel-czerwinski-ukSa5ogrv3Y-unsplash.jpg?alt=media&token=627c5793-88ac-446f-92a3-87692a666fb8",
        
        // Karlsbad Pillars at Night
        "https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2F_owner%2F1732488395751_claudio-schwarz-BYHi1Ztuo-0-unsplash%20(1).jpg?alt=media&token=9d60eaa1-ccae-4d9c-8771-2796e93c87e5",

        // red blue swerls
        "https://firebasestorage.googleapis.com/v0/b/day-in-life-app.appspot.com/o/userMedia%2F3zdeUW5gl7TSqWs0tNTOjHG8ZsD3%2F1732490565932_pawel-czerwinski-6HFXd4pEpgI-unsplash.jpg?alt=media&token=257d207d-33c2-4c65-8caa-ca807c3c8bd4",
    ],
    strings: {
        achievementTitle: 'Great Work Today, ##userName##!',
        achievementMessage: 'You have completed your daily dose of movement break.',
        achievementFinePrint: 'You deserve a break, go get some fresh air. See you again tomorrow.',

        biometricThankYouTitle: 'Great work, completing your movement break ##userName##.',
        biometricThankYouMessage: 'Thanks for providing your post-scores',
        biometricThankYouFinePrint: 'We will notify you at the time of your next movement break.',

        addAnotherProgramToYourDay: "Add Another Program To Your Day",
        swapYourPrimaryProgram: "Swap Your Primary Program",

biometricThankYouIcons: 
`trophy
ribbon
fitness
diamond
medal
sparkles
star`,
biometricThankYouTitles:
`Great work
Nice!
Well done
Kudos
Keep it up`,
biometricThankYouMessages:
`Nice work ##userName##! We've recorded your movements - you are right on track.
Great job, ##userName##! Your movements are spot on. Keep up the awesome work!
Fantastic effort, ##userName##! Your movements have been logged, and you're doing great!
Way to go, ##userName##! Your movements are right where they should be. Keep it up!
Excellent work, ##userName##! Your movements are on point. Keep pushing forward!`,
biometricThankYouFinePrints: '',

        activityVerb: 'Activity', // aka Meditate
        activityVerbPlural: 'Activities', // aka Meditations
        activityVerbPastTense: 'Completed Activities', // TODO: This might not be grammatically correct, or a verb (aka Meditated)

        emptyState: 'No Activity Yet',
    },
    styles: {
        // APP
        service: 'background: #000; color: #FFF; font-weight: bold; font-size: 24px;', // Black background with white text for highest importance and contrast
        component: 'background: #333; color: #7FFF7F; font-weight: bold;', // Dark grey with bright green text for components, indicating functionality and visibility
        destroyed: 'background: #E1E6E1; color: #D33;', // Light grey with dark red to indicate a process being stopped or removed
    
        // INFO
        title: 'font-size: 96px; font-weight: bold; color: #5DADE2; border: 1px solid #000;', // Light blue for primary information, large and bold
        app: 'font-size: 72px; font-weight: bold; color: #0011DD; letter-spacing: -16px; -webkit-text-stroke: 1px black;', // Dark blue for application-specific info
        heading: 'font-size: 48px; font-weight: bold; color: #2E86C1; letter-spacing: -16px; -webkit-text-stroke: 1px black;', // Medium blue for headings
        subheading: 'font-size: 32px; font-weight: bold; color: #85C1E9; letter-spacing: -16px; -webkit-text-stroke: 1px black;', // Light blue for subheadings
    
        // SUCCESS
        success: 'background: #28A745; color: #FFF; font-weight: bold;', // Green background for success
        completed: 'background: #0062CC; color: #FFF; font-weight: bold;', // Blue background for completed tasks
    
        // ACME #s
        acme: 'background: #C71585; color: #FFF; font-weight: bold; font-size: 24px;', // Medium Violet Red for specific identifiers
        acme2: 'background: #28A745; color: #FFF; font-weight: bold; font-size: 24px;', // Success green
        acme3: 'background: #007BFF; color: #FFF; font-weight: bold; font-size: 24px;', // Blue
        acme4: 'background: #FF5733; color: #FFF; font-weight: bold; font-size: 24px;', // Bright red for deprecated
    
        // ACME Labels
        acmeFresh: 'background: #5CB85C; color: #FFF; font-weight: bold; font-size: 24px;', // Fresh green
        acmeEnroll: 'background: #0056B3; color: #FFF; font-weight: bold; font-size: 24px;', // Enrollment blue
        acmeTodo: 'background: #FFD700; color: #000; font-weight: bold; font-size: 24px;', // Yellow for to-do items
        acmeSecurityIssue: 'background: #FFD700; color: red; font-weight: bold; font-size: 24px;', // security issue
        acmeError: 'background: #FF0000; color: #FFF; font-weight: bold; font-size: 24px;', // Red for errors
        acmeWarn: 'background: #FFA500; color: #FFF; font-weight: bold; font-size: 24px;', // Orange for warnings
        acmeHeal: 'background: #EBD6FF; color: #F75F80; font-weight: bold; font-size: 24px;', // Light purple for healing
        acmeLoading: 'background: #4367FC; color: #FFF; font-weight: bold; font-size: 24px;', // Bright blue for loading state
        acmeLoaded: 'background: #0838FB; color: #FFF; font-weight: bold; font-size: 24px;', // Darker blue for loaded state
        acmeComponent: 'background: #000; color: #FFF; font-weight: bold; font-size: 24px;',
        acmeCoder: 'background: #000; color: #33FF33; font-weight: bold; font-size: 24px;',
    
        // PROCESSING
        processing: 'background: #28A745; color: #FFF; font-weight: bold;', // Bright green background with white text for active processing
        processed: 'background: #218838; color: #FFF; font-weight: bold;', // Slightly darker green background with white text for completed processing

        routing: 'background: #ECF0F1; color: #2980B9;', // Light background with bright blue text for routing
        event: 'background: #F1C40F; color: #000; font-weight: bold;', // Yellow background for events
    
        // AUTH
        authorize: 'background: #DCD6F7; color: #FFF;', // Light purple for authorization start
        authorizing: 'background: #A6B1E1; color: #FFF;', // Medium purple for authorization in progress
        authorized: 'background: #00416D; color: #FFF;', // Dark blue for completed authorization
        loggedOut: 'background: #000; color: #FFF;', // Black for logged out status
    
        // FILTER
        calc: 'background: #186705; color: #FFF;', // Dark green for calculation processes
        fresh: 'background: #5CB85C; color: #FFF;', // Fresh green for new data
        filtered: 'background: #A3FFB4; color: #000;', // Light green for filtered results
        reset: 'background: #E1FF00; color: #000;', // Bright yellow for resets
    
        // RESET
        resetProgram: 'background: #E1FF00; color: #000; font-weight: bold; font-size: 24px;', // Bright yellow for program resets
    
        // LOADING
        loading: 'background: #4367FC; color: #FFF;', // Bright blue for loading state
        loaded: 'background: #0838FB; color: #FFF;', // Darker blue for loaded state
    
        // READ
        reading: 'background: #7EA9FF; color: #FFF;', // Light blue for reading
        read: 'background: #4483FF; color: #FFF;', // Medium blue for read
    
        // WRITE
        writing: 'background: #CD49FC; color: #FFF;', // Light purple for writing
        written: 'background: #BB08FB; color: #FFF;', // Darker purple for written
    
        // DELETE
        deleting: 'background: #EC645B; color: #FFF;', // Bright red for deleting
        deleted: 'background: #E63B2E; color: #FFF;', // Darker red for deleted
    
        // DISABLED / DEPRECIATED
        disabled: 'background: #301; color: #FF4500; font-weight: bold;', // Dark red background for disabled
        depreciated: 'background: #996600; color: #FFF; font-weight: bold;', // Brown background for deprecated
    
        // INFO / ERROR / WARN
        info: 'background: #EDFDFB; color: #28D0FF; font-weight: bold;', // Light blue for information
        error: 'background: #FF0000; color: #FFF;', // Red for errors
        warn: 'background: #FFA500; color: #FFF;', // Orange for warnings
        resolved: 'background: #FFFF00; color: #000;', // Yellow for resolved issues
    
        // NOTIFICATION
        notification: 'background: #800080; color: #FFF; font-weight: bold;', // Purple for notifications
    
        // DEVELOPER
        coder: 'background: #000; color: #33FF33; font-weight: bold;', // Black with green text for code-related logs
        coderReminder: 'background: #000; color: #FFA500; font-weight: bold;', // Black with orange for reminders
        constant: 'background: #000; color: #DDD8B8; font-weight: bold;', // Black with light beige for constants
        missing: 'background: #DDD; color: #FFA500; font-weight: bold;', // Light grey with orange for missing items
        heal: 'background: #EBD6FF; color: #F75F80; font-weight: bold;', // Light purple for healing
        repair: 'background: #6A5ACD; color: #FFA500; font-weight: bold;', // Slate blue for repairs
        override: 'background: #EBD6FF; color: #5F5FF7; font-weight: bold;', // Light purple for overrides
        stop: 'background: #FFFF00; color: #FF0000;', // Yellow with red for stop
        techDept: 'background: #7d6c75; color: #6c7d74; font-weight: bold;',
        todo: 'background: #EFFDE8; color: #FF8C00; font-weight: bold;', // Light green with dark orange for to-do
        test: 'background: #000; color: #FFFF00; font-weight: bold;', // Black with yellow for tests
        testing: 'background: #000; color: #FFFF00; font-weight: bold; font-size: 24px;', // Black with yellow, larger for testing
        highlight: 'background: #FF66FF; color: #FFF; font-weight: bold; font-size: 24px;', // Bright pink for highlights
    
        // PLAYER
        biometric: 'background: #00000022; color: #3880FF; font-weight: bold;', // Very light grey with blue for biometric
        habit: 'background: #CCFFCC; color: #99CC00; font-weight: bold;', // Light green for habit
    
        // Additional Styles
        initial: 'background: #2ECC71; color: #FFF; font-weight: bold;', // Green for initial steps
        intermediate: 'background: #3498DB; color: #FFF;', // Blue for intermediate steps
        final: 'background: #E74C3C; color: #FFF; font-weight: bold;', // Red for final steps
        paused: 'background: #F39C12; color: #FFF;', // Orange for paused processes
        resumed: 'background: #8E44AD; color: #FFF;', // Purple for resumed processes
    },
    toast: {
        shortDelay: 3000,
        longDelay: 6000,
        extraLongDelay: 9000,
        superExtraLongDelay: 160000,
        debugDelay: 320000,
    },
    colors: {
        gray: '#AEB2B2',
        reef: '#00d2ff',
        aqua: '#aqua',
        kyoto: '#c21500',
        success: '#50D615',
        warn: '#EFAF0A',
        danger: '#F50B0B',
    },
    rgbColors: {
        white: '255, 255, 255',
        black: '0, 0, 0',
        blue: '66, 191, 221',
        red: '173, 46, 36',
        pinko: '254,100,180',
        mediumSpringGreen: '0, 250, 154',
        green: '72, 169, 166',
        yellow: '255, 210, 90',
        brown: '165, 42, 42',
        orange: '255, 165, 0',
        violet: '238,130,238', // very close to magenta, not as bright or saturated
        magenta: '255, 0, 255',
        teal: '127, 255, 212',
        purple: '221, 160, 221',
        lime: '0,255,0',
        gray: '128,128,128',
        coral: '255,127,80',
        navy: '0,0,128',
        honeydew: '240,255,240',
        tomato: '255, 99, 71',
        thistle: '216, 191, 216',
        goldenRod: '218, 165, 32',
        lightSalmon: '255, 160, 122',
        lightGreen: '144, 238, 144',
        olive: '128, 128, 0',
        mediumPurple: '147, 112, 219',
        mediumOrchid: '186, 85, 211',
        khaki: '240, 230, 140',
        chocolate: '210, 105, 30',
    },
    programColors: ['#3F4449', '#E58422', '#d5230b', '#716bb3', '#7A45E5'],
    biometrics: {
        moods: {
            happy: '/assets/icons/mood/happy.svg',
        }
    },
    limits: {
        messages: 100,
        habits: 600,
        maxRecords: 3000, // = 1 year of 4 daily activity, with 2 trackables daily -> 3000 / 365 / (2 * 4)
    },
    delays: {
        autoSaveDebounce: 5000,
    },
    quotes: [
        'Rise up. Start fresh. See the bright opportunity in each new day.',
        'Do or do not, there is no try',
        'The obstacle is the path.',
        'May you live every day of your life.',
        'Whatever you are, be a good one.'
    ],
    costs: {
        sms: 0.0149, // sms + carrier fees (0.0079 + 0.007)
        email: 0.00133,
    }
};
