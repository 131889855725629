import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { defaults } from 'src/constants/constants';
import { HelpersService } from './Helpers.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root', // This makes the service globally available
})
export class ToastService {
  constructor(
    private toastController: ToastController,
    private router: Router,
  ) { }

  toastQueue: string[] = [];
  isToastActive = false;

  public async queueToast(message: string) {
    this.toastQueue.push(message);
    if (!this.isToastActive) {
      await this.processToastQueue();
    }
  }

  private async processToastQueue() {
    if (this.toastQueue.length === 0) return;
    this.isToastActive = true;

    const message = this.toastQueue.shift()!;
    const toast = await this.toastController.create({
      message,
      duration: 2000,
      position: 'bottom',
    });
    await toast.present();

    // Wait for the toast to disappear before showing the next one
    toast.onDidDismiss().then(() => {
      this.isToastActive = false;
      this.processToastQueue();
    });
  }

  public async presentToast(message, color = 'dark', position: "bottom" | "top" | "middle" = "bottom", duration = defaults.toast.shortDelay, button?) {
    if (!message) {
      return;
    }
    let toast = await this.toastController.create({
      message,
      color,
      duration,
      position,
    });

    // button?
    if (button) {
      toast.buttons = [
        {
          text: button.text, // 'View Notes',
          role: button.role, // 'view notes',
          handler: () => {
            console.log(button.text + ' button clicked');
            if (button.url) {
              HelpersService.openExternalLink(button.url);
            }
            if (button.route) {
              this.router.navigate([button.route]);
            }
            // this.addHabit(this.lastHabitRemoved);
          }
        }
      ]
    }
    toast.present();
  }
}
