// tslint:disable: max-line-length
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { defaults } from 'src/constants/constants';

/*
 ███████████  █████         █████████   █████ █████ ██████████ ███████████  
░░███░░░░░███░░███         ███░░░░░███ ░░███ ░░███ ░░███░░░░░█░░███░░░░░███ 
 ░███    ░███ ░███        ░███    ░███  ░░███ ███   ░███  █ ░  ░███    ░███ 
 ░██████████  ░███        ░███████████   ░░█████    ░██████    ░██████████  
 ░███░░░░░░   ░███        ░███░░░░░███    ░░███     ░███░░█    ░███░░░░░███ 
 ░███         ░███      █ ░███    ░███     ░███     ░███ ░   █ ░███    ░███ 
 █████        ███████████ █████   █████    █████    ██████████ █████   █████
░░░░░        ░░░░░░░░░░░ ░░░░░   ░░░░░    ░░░░░    ░░░░░░░░░░ ░░░░░   ░░░░░ 
*/

const playerRoutes = [
  // App starting point
  { path: '', redirectTo: defaults.platform.startupRoute ?? 'login', pathMatch: 'full' },

  // Temp Developer
  { path: 'developer', loadChildren: () => import('./player/developer/developer.module').then( m => m.DeveloperPageModule) },

  // Login
  { path: 'register', loadChildren: () => import('./common/register/register.module').then( m => m.RegisterPageModule) },
  { path: 'login', loadChildren: () => import('./common/login/login.module').then( m => m.LoginPageModule) },
  { path: 'login-with-email', loadChildren: () => import('./common/login-with-email/login-with-email.module').then( m => m.LoginWithEmailPageModule) },
  { path: 'login/:academyAlias', loadChildren: () => import('./common/login/login.module').then( m => m.LoginPageModule) },
  { path: 'logout', loadChildren: () => import('./common/logout/logout.module').then( m => m.LogoutPageModule) },
  { path: 'reset-password', loadChildren: () => import('./common/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule) },
  { path: 'terms', loadChildren: () => import('./common/terms/terms.module').then( m => m.TermsPageModule) },
  { path: 'academy', loadChildren: () => import('./player/academy/academy.module').then( m => m.AcademyPageModule) },
  { path: 'enroll', loadChildren: () => import('./player/enroll/enroll.module').then( m => m.EnrollPageModule) },
  { path: 'subscribe', loadChildren: () => import('./player/enroll/enroll.module').then( m => m.EnrollPageModule) },
  { path: 'enroll/:academyId/:programId', loadChildren: () => import('./player/enroll/enroll.module').then( m => m.EnrollPageModule) },
  { path: 'complete-order', loadChildren: () => import('./player/complete-order/complete-order.module').then(m => m.CompleteOrderPageModule) },
  { path: 'complete-order/:session_id', loadChildren: () => import('./player/complete-order/complete-order.module').then(m => m.CompleteOrderPageModule) },
  { path: 'cancel', loadChildren: () => import('./player/gateway-cancel/gateway-cancel.module').then(m => m.GatewayCancelPageModule) },
  { path: 'enroll-with-email', loadChildren: () => import('./player/enroll-with-email/enroll-with-email.module').then( m => m.EnrollWithEmailPageModule) },
  { path: 'enroll-with-email/:academyId', loadChildren: () => import('./player/enroll-with-email/enroll-with-email.module').then( m => m.EnrollWithEmailPageModule) },
  
  // Privacy & Security Required Pages
  { path: 'delete-account', loadChildren: () => import('./delete-account/delete-account.module').then( m => m.DeleteAccountPageModule) },
  { path: 'privacy', loadChildren: () => import('./privacy/privacy.module').then( m => m.PrivacyPageModule) },

  // HOME PAGE
  // Client player (Home page not implemented yet)
  { path: 'home', loadChildren: () => import('./academy/home/home.module').then( m => m.HomePageModule) },
  { path: 'home/:id', loadChildren: () => import('./academy/home/home.module').then( m => m.HomePageModule) },
  { path: 'academy/:alias', loadChildren: () => import('./academy/home/home.module').then( m => m.HomePageModule) },
  { path: 'a/:alias', loadChildren: () => import('./academy/home/home.module').then( m => m.HomePageModule) },
  { path: 'dial-home/:alias', loadChildren: () => import('./academy/home/home.module').then( m => m.HomePageModule) },
  { path: 'dial-home/:alias/:referral', loadChildren: () => import('./academy/home/home.module').then( m => m.HomePageModule) },
  { path: 'profile/:alias', loadChildren: () => import('./academy/home/home.module').then( m => m.HomePageModule) },
  { path: 'profile/:alias/:referral', loadChildren: () => import('./academy/home/home.module').then( m => m.HomePageModule) },

  { path: 'workout', loadChildren: () => import('./player/workout/workout.module').then( m => m.WorkoutPageModule) },
  { path: 'workout/:id', loadChildren: () => import('./player/workout/workout.module').then( m => m.WorkoutPageModule) },

  { path: 'settings', loadChildren: () => import('./common/settings/settings.module').then( m => m.SettingsPageModule) },

  // LEGACY PLAYER
  // { path: 'play', loadChildren: () => import('./player/program/program.module').then( m => m.ProgramPageModule) },
  
  // PLAYER
  // NEW PLAYER VERSION 2.0
  // { path: 'play1', loadChildren: () => import('./player/program/program.module').then( m => m.ProgramPageModule) },
  // { path: 'play', loadChildren: () => import('./player/program2/program2.module').then( m => m.Program2PageModule) },
  // { path: 'play/:academyAlias', loadChildren: () => import('./player/program2/program2.module').then( m => m.Program2PageModule) },
  { path: 'play/:loginAsUserId', loadChildren: () => import('./player/program2/program2.module').then( m => m.Program2PageModule) },
  // alternative
  { path: 'player', redirectTo: 'play', pathMatch: 'full' },
  
  // NEW PLAYER VERSION 3.0
  { path: 'play', loadChildren: () => import('./player/program3/program3.module').then( m => m.Program3PageModule) },

  { path: 'achievements', loadChildren: () => import('./player/achievements/achievements.module').then( m => m.AchievementsPageModule) },
  { path: 'meditate', loadChildren: () => import('./player/meditate/meditate.module').then( m => m.MeditatePageModule) },
  { path: 'my-programs/:id/:programId', loadChildren: () => import('./player/my-programs/my-programs.module').then( m => m.MyProgramsPageModule) },
  { path: 'video-player', loadChildren: () => import('./player/video-player/video-player.module').then( m => m.VideoPlayerPageModule) },
  
  { path: 'user-habit-history', loadChildren: () => import('./player/history/user-habit-history/user-habit-history.module').then( m => m.UserHabitHistoryPageModule) },
  { path: 'user-workout-history', loadChildren: () => import('./player/history/user-workout-history/user-workout-history.module').then( m => m.UserWorkoutHistoryPageModule) },
  { path: 'week-ahead', loadChildren: () => import('./player/week-ahead/week-ahead.module').then( m => m.WeekAheadPageModule) },
  { path: 'report', loadChildren: () => import('./player/report/report.module').then( m => m.ReportPageModule) },
  { path: 'onboarding', loadChildren: () => import('./player/onboarding/onboarding.module').then( m => m.OnboardingPageModule) },
  { path: 'questions', loadChildren: () => import('./player/onboarding/questions/questions.module').then( m => m.QuestionsPageModule) },
  { path: 'user-biometric-history', loadChildren: () => import('./player/history/user-biometric-history/user-biometric-history.module').then( m => m.UserBiometricHistoryPageModule) },
  { path: 'activity', loadChildren: () => import('./academy/activity/activity.module').then( m => m.ActivityPageModule) },
  { path: 'account', loadChildren: () => import('./common/account/account.module').then( m => m.AccountPageModule) },
  { path: 'your-goal', loadChildren: () => import('./player/onboarding/your-goal/your-goal.module').then( m => m.YourGoalPageModule) },
  { path: 'notes', loadChildren: () => import('./player/notes/notes.module').then( m => m.NotesPageModule) },
  { path: 'notifications', loadChildren: () => import('./player/notifications/notifications.module').then( m => m.NotificationsPageModule) },
  { path: 'focus', loadChildren: () => import('./player/focus/focus.module').then( m => m.FocusPageModule) },

  // PROGRAMS PREVIEW
  { path: 'programs', loadChildren: () => import('./player/programs/programs.module').then( m => m.ProgramsPageModule) },
  { path: 'unlimited', loadChildren: () => import('./player/programs/programs.module').then( m => m.ProgramsPageModule) },
  { path: 'programs/:id', loadChildren: () => import('./player/programs/programs.module').then( m => m.ProgramsPageModule) },
  { path: 'programs/coach/:referral', loadChildren: () => import('./player/programs/programs.module').then( m => m.ProgramsPageModule) },

  // PROGRAM PREVIEW
  { path: 'dial/:alias', loadChildren: () => import('./player/program-preview/program-preview.module').then( m => m.ProgramPreviewPageModule) }, // alias for program-preview
  { path: '@/:alias', loadChildren: () => import('./player/program-preview/program-preview.module').then( m => m.ProgramPreviewPageModule) }, // alias for program-preview
  { path: 'program', loadChildren: () => import('./player/program-preview/program-preview.module').then( m => m.ProgramPreviewPageModule) }, // alias for program-preview
  { path: 'program/:programId', loadChildren: () => import('./player/program-preview/program-preview.module').then( m => m.ProgramPreviewPageModule) }, // alias for program-preview
  { path: 'program/:programId/:referral', loadChildren: () => import('./player/program-preview/program-preview.module').then( m => m.ProgramPreviewPageModule) }, // alias for program-preview
  { path: 'program-preview', loadChildren: () => import('./player/program-preview/program-preview.module').then( m => m.ProgramPreviewPageModule) },
  { path: 'free', loadChildren: () => import('./player/program-preview/program-preview.module').then( m => m.ProgramPreviewPageModule) },
  { path: 'program-preview/:programId', loadChildren: () => import('./player/program-preview/program-preview.module').then( m => m.ProgramPreviewPageModule) },
  { path: 'program-preview/:academyId/:programId', loadChildren: () => import('./player/program-preview/program-preview.module').then( m => m.ProgramPreviewPageModule) },
  { path: 'program-preview/:academyId/:programId/:referral', loadChildren: () => import('./player/program-preview/program-preview.module').then( m => m.ProgramPreviewPageModule) },
  { path: 'program/:academyId/:programId',  loadChildren: () => import('./player/program-preview/program-preview.module').then( m => m.ProgramPreviewPageModule) },

  // WORKOUT
  { path: 'workout', loadChildren: () => import('./player/workout/workout.module').then( m => m.WorkoutPageModule) },
  { path: 'workout-player', loadChildren: () => import('./player/workout-player/workout-player.module').then( m => m.WorkoutPlayerPageModule) },
  { path: 'workout-completed', loadChildren: () => import('./player/workout-completed/workout-completed.module').then( m => m.WorkoutCompletedPageModule) },
  { path: 'workout-rest', loadChildren: () => import('./player/workout-rest/workout-rest.module').then( m => m.WorkoutRestPageModule) },
  { path: 'workout-rep-range', loadChildren: () => import('./player/workout-rep-range/workout-rep-range.module').then( m => m.WorkoutRepRangePageModule) },
  { path: 'workout-rep-range/:id', loadChildren: () => import('./player/workout-rep-range/workout-rep-range.module').then( m => m.WorkoutRepRangePageModule) },

  // MESSAGING
  { path: 'messaging', loadChildren: () => import('./player/messaging/messaging.module').then( m => m.MessagingPageModule) },
  { path: 'messages/:id', loadChildren: () => import('./player/messages/messages.module').then( m => m.MessagesPageModule) },
];

/*
 ██████   ██████   █████████   ██████   █████   █████████     █████████  ██████████
░░██████ ██████   ███░░░░░███ ░░██████ ░░███   ███░░░░░███   ███░░░░░███░░███░░░░░█
 ░███░█████░███  ░███    ░███  ░███░███ ░███  ░███    ░███  ███     ░░░  ░███  █ ░ 
 ░███░░███ ░███  ░███████████  ░███░░███░███  ░███████████ ░███          ░██████   
 ░███ ░░░  ░███  ░███░░░░░███  ░███ ░░██████  ░███░░░░░███ ░███    █████ ░███░░█   
 ░███      ░███  ░███    ░███  ░███  ░░█████  ░███    ░███ ░░███  ░░███  ░███ ░   █
 █████     █████ █████   █████ █████  ░░█████ █████   █████ ░░█████████  ██████████
░░░░░     ░░░░░ ░░░░░   ░░░░░ ░░░░░    ░░░░░ ░░░░░   ░░░░░   ░░░░░░░░░  ░░░░░░░░░░                                                                                  
*/

const manageRoutes = [
  // Platform Management
  { path: 'platform', loadChildren: () => import('./platform/platform.module').then( m => m.PlatformPageModule) },
  { path: 'global', loadChildren: () => import('./platform/platform.module').then( m => m.PlatformPageModule) },
  
  // Academy Management
  { path: 'manage', loadChildren: () => import('./manage/manage.module').then( m => m.ManagePageModule) },
  { path: 'dashboard', loadChildren: () => import('./manage/manage.module').then( m => m.ManagePageModule) },

  // Program Editor
  { path: 'edit-program/:programId', loadChildren: () => import('./manage/program/program.module').then( m => m.ProgramPageModule) },
  // Program Editor for Member Program
  { path: 'user/:memberId/academy/:academyId/program/:programId', loadChildren: () => import('./manage/program/program.module').then( m => m.ProgramPageModule) }, // original (already in use)
  // { path: 'program/:programId/member/:memberId/academy/:academyId', loadChildren: () => import('./manage/program/program.module').then( m => m.ProgramPageModule) }, // considering changing route (but wasn't confident)

  { path: 'workouts', loadChildren: () => import('./platform/workouts/workouts.module').then( m => m.WorkoutsPageModule) },

  { path: 'page-designer', loadChildren: () => import('./manage/page-designer/page-designer.module').then( m => m.PageDesignerPageModule) },
  
  { path: 'discussions', loadChildren: () => import('./manage/discussions/discussions.module').then( m => m.DiscussionsPageModule) },
  { path: 'discussions/:id', loadChildren: () => import('./manage/discussions/discussions.module').then( m => m.DiscussionsPageModule) },
  { path: 'discussion', loadChildren: () => import('./manage/discussion/discussion.module').then( m => m.DiscussionPageModule) },
  { path: 'discussion/:id', loadChildren: () => import('./manage/discussion/discussion.module').then( m => m.DiscussionPageModule) },

  { path: 'getting-started', loadChildren: () => import('./manage/getting-started/getting-started.module').then( m => m.GettingStartedPageModule)},
];

/*
  █████████     ███████      █████████  █████   █████████   █████      
 ███░░░░░███  ███░░░░░███   ███░░░░░███░░███   ███░░░░░███ ░░███       
░███    ░░░  ███     ░░███ ███     ░░░  ░███  ░███    ░███  ░███       
░░█████████ ░███      ░███░███          ░███  ░███████████  ░███       
 ░░░░░░░░███░███      ░███░███          ░███  ░███░░░░░███  ░███       
 ███    ░███░░███     ███ ░░███     ███ ░███  ░███    ░███  ░███      █
░░█████████  ░░░███████░   ░░█████████  █████ █████   █████ ███████████
 ░░░░░░░░░     ░░░░░░░      ░░░░░░░░░  ░░░░░ ░░░░░   ░░░░░ ░░░░░░░░░░░ 
*/

const socialRoutes = [
  { path: 'academies', loadChildren: () => import('./global/academies/academies.module').then( m => m.AcademiesPageModule) },
  { path: 'humans', loadChildren: () => import('./global/humans/humans.module').then( m => m.HumansPageModule) },
  { path: 'skills', loadChildren: () => import('./global/skills/skills.module').then( m => m.SkillsPageModule) },
  { path: 'habits', loadChildren: () => import('./global/habits/habits.module').then( m => m.HabitsPageModule) },
];

const routes: Routes = playerRoutes.concat(manageRoutes).concat(socialRoutes);

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
