import { defaults } from 'src/constants/constants';
import { ModalController, NavController, Platform, PopoverController, ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable()
export class AppService {

  constructor(
    public navCtrl: NavController,
    public platform: Platform,
    private toastController: ToastController,
    private popoverController: PopoverController,
    private modalController: ModalController,
  ) { }

  public static isApp;
  public version = defaults.version;

  public userId;

  initialize() {
    console.log('This should run first. Initializing application.');
    // this.checkVersion();
  }

  goBack() {
    this.navCtrl.pop();
  }

  navigateForward(url: string) {
    this.navCtrl.navigateForward(url);
  }

  navigateRoot(url: string) {
    this.navCtrl.navigateRoot(url);
  }

  setPlatform() {
    if (this.platform.is('mobileweb')) {
      AppService.isApp = false;
    } else {
      AppService.isApp = true;
    }
    console.log('isApp', AppService.isApp);
  }

  async presentToast(message, color = 'dark') {
    if (!message) {
      return;
    }
    const toast = await this.toastController.create({
      message,
      color,
      duration: defaults.toast.shortDelay,
    });
    toast.present();
  }

  async dismiss(objectToReturn?: any) {
    if (objectToReturn) console.log('DONE -> Returning data object', objectToReturn);

    try {
      // First check if there's a modal on top
      const topModal = await this.modalController.getTop();
      const topPopover = await this.popoverController.getTop();

      // Determine which overlay is actually on top
      if (topModal && (!topPopover || topModal.id > topPopover.id)) {
        // Modal is on top
        await this.modalController.dismiss(objectToReturn);
      } else if (topPopover) {
        // Popover is on top
        await this.popoverController.dismiss(objectToReturn);
      } else {
        console.error('No modal or popover is active to dismiss.');
      }
    } catch (error) {
      console.error('Dismissal failed:', error);
    }
  }

  async copyValue(value: string) {
    try {
      await navigator.clipboard.writeText(value);
      this.presentToast('Copied to clipboard!');
    } catch (err) {
      this.presentToast('Failed to copy to clipboard');
      console.error('Failed to copy to clipboard: ', err);
    }
  }
}
