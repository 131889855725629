// tslint:disable: max-line-length
import { catchError, first, map } from 'rxjs/operators';
import { defaults } from 'src/constants/constants';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore, DocumentReference, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { Observable, BehaviorSubject, from } from 'rxjs';
import { LoadingController } from '@ionic/angular';
import { HelpersService } from './Helpers.service';
import { environment } from 'src/environments/environment';

export interface Testimonial {
  content: string;
  author: string;
  image: string;
}
// Member model interface (adjust fields as per your Firestore document structure)
interface Member {
  // ... your member properties here
  waiveFees?:boolean;
}
export interface Academy {
  id?: string;
  uid?: string;

  // META
  updatedAt?: number; // server timestamp
  updatedAtLocal?: number; // local client timestamp
  createdAt?: number; // server timestamp

  // BRANDING
  logo?: string;
  logoDark?: string;
  bgColor?: string;
  colorPrimary?: string;
  image?: string;

  // SITE NAME
  name: string;
  address: string;
  slug?: string;

  isPublic?: boolean;
  enrollViaEmailOnly?: boolean;
  allowMultiplePrograms?: boolean;
  defaultProgram?: string;
  canMessageOtherUsers?: boolean

  // SITE HEADER (OLD)
  // siteHeaderText: string;
  // siteSubHeaderText: string;
  // siteHeader?: string;

  featuredIn?: string; // Image URL

  // NAVIGATION
  navigation?: {};

  // HERO BANNER
  heroBanner?: {};

  // FEATURES
  features?: {};

  // ANALYTICS
  reads?: number;
  totalEarned?: number; // Total earned from program sales from clients
  totalSpent?: number;  // Total spend on HYH services

  testimonial?: Testimonial;

  // COACH
  coachTitle?: string;
  coachSummary?: string;
  coachQuote?: string;
  youTubeVideoId?: string;

  // PROGRAMS
  programHeadline?: string;

  // BANNER
  bannerTitle?: string;
  bannerSubTitle?: string;
  bannerSubSubTitle?: string;
  bannerImage?: string;
  bannerCTA?: string;

  // CONTACT
  coachName?: string;
  coachAvatar?: string;
  contactTitle?: string;
  contactParagraph?: string;
  email?: string;
  phone?: string;

  // SOCIAL
  instagram?: string;
  twitterHandle?: string;
  facebook?: string;

  // CLOSING STATEMENT
  closingStatement?: string;
  closingImage?: string;

  // CLOSING STATEMENT BANNER
  closingBanner?: any;

  // PAYMENTS
  currencyDefault?: string,

  // COUPONS
  couponsEnabled?: boolean,

  // PREVIEW (demo mode)
  noSubscriptionLimitedViewEnabled?: boolean;
  numberOfHabitsInDemo?: number;

  // PLAYER BRANDING
  playerColorBg?: string;
  playerColorBgDarkMode?: string;
  playerColorPrimary?: string;
  playerColorPrimaryContrast?: string;
  playerColorLight?: string;
  playerColorMedium?: string;
  playerColorDark?: string;

  playerColorSuccess?: string;
  playerColorWarn?: string;
  playerColorDanger?: string;

  playerCenterHeadings?: boolean;

  // BUTTONS
  buttonFill?: string;

  playerMenuSelectProgram?: boolean;
  playerMenuPreviewWeekAhead?: boolean;
  playerMenuActivityFeed?: boolean;
  playerMenuManageHabits?: boolean;
  playerMenuOnboarding?: boolean;
  playerMenuQuestions?: boolean;
  playerMenuReport?: boolean;
  playerMenuNotes?: boolean;
  playerMenuSetYourGoal?: boolean;
  playerMenuDiscussions?: boolean;

  // PLAYER LOOK
  overlapMastheadWithHabitFeed?: boolean;
  
  // COMPONENTS
  showStories?: boolean;
  showReadinessScore?: boolean;
  showProgressBar?: boolean;
  showReportCard?: boolean; // hideReportCard is depreciated
  showConsecutiveDays?: boolean;
  showActivityStreak?: boolean;
  showMessagingIcon?: boolean;
  showMotivationalQuote?: boolean;

  // STORIES
  autoplayVideos?: boolean;
  autoplayAudio?: boolean;

  // REPORT
  reportActivityTypeReport?: boolean;
  reportShowExperiencePoints?: boolean;
  reportShowActivityCompliance?: boolean;
  reportShowBiometricCompliance?: boolean;
  reportShowActivityHistory?: boolean;
  reportShowBiometricHistory?: boolean;
  reportShowWorkoutHistory?: boolean;
  reportShowNotes?: boolean;
  
  // REPORT CARD
  reportCardTitle?: string;
  reportCardSubTitle?: string;
  reportCardShowCompletedCount?: boolean;
  reportCardShowCompletedOutOfTotal?: boolean;
  reportCardShowPercentageCircle?: boolean;

  // TEMPLATES
  landingTemplate?: string;
  programPreviewTemplate?: string;
  showButtonsInFooterInsteadOfToolbar?: boolean;

  // PROGRAM SELECT
  showAcademyNameInMasthead?: boolean;
  hideProgramTileInMastHead?: boolean;

  // ONBOARDING - SLIDES
  onboardingSlides?: any[];
  onboardingSkipSlides?: boolean;
  onboardingSlidesLogo?: string;
  onboardingSlidesLogoRight?: string;
  
  // ONBOARDING - QUESTIONS
  onboardingSkipQuestions?: boolean;
  customQuestionsEnabled?: boolean;
  customQuestions?: any;
  faqs?: any[];
  eventsBanner?: {};
  textAlign?: 'left';
  questions?: {};
  notifications?: {}[];
  equipment?: {}[];
  goals?: {}[];
  
  // ONBOARDING - SLIDES (NOT COMPLETED)
  // ALERT
  presentAlertIfOnboardingNotCompleted?: boolean;
  onboardingNotCompletedAlertName?: string;
  onboardingNotCompletedAlertDescription?: string;
  onboardingNotCompletedAlertColor?: string;
  // TOAST
  presentToastIfOnboardingNotCompleted?: boolean;
  onboardingNotCompletedToastName?: string;
  onboardingNotCompletedToastButton?: string;
  onboardingNotCompletedToastColor?: string;

  // ONBOARDING - QUESTIONS (NOT COMPLETED)
  // ALERT
  presentAlertIfOnboardingQuestionsNotCompleted?: boolean;
  onboardingQuestionsNotCompletedAlertName?: string;
  onboardingQuestionsNotCompletedAlertDescription?: string;
  onboardingQuestionsNotCompletedAlertColor?: string;
  // TOAST
  presentToastIfOnboardingQuestionsNotCompleted?: boolean;
  onboardingQuestionsNotCompletedToastName?: string;
  onboardingQuestionsNotCompletedToastButton?: string;
  onboardingQuestionsNotCompletedToastColor?: string;

  // SUBSCRIPTION
  academyWideSubscription?: boolean; // subscribing to any pay program, will give Academy wide access
  
  // ALERT (no subscription)
  noSubscriptionShowAlert?: boolean;
  noSubscriptionAlertName?: string;
  noSubscriptionAlertDescription?: string;
  noSubscriptionAlertButton?: string;
  noSubscriptionAlertURL?: string;
  // TOAST (no subscription)
  noSubscriptionToastEnabled?: boolean;
  noSubscriptionToastMessage?: string;

  // EMAIL TEMPLATES
  welcomeEmail?: string;
  enrollmentEmail?: string;
  inactivityEmail?: string;

  // EMAIL ACTIVE SETTING
  sendMemberWelcomeEmail?: boolean;
  sendEnrollmentEmail?: boolean;
  sendInactivityEmail?: boolean;

  // ENROLLMENT BACKGROUND
  enrollmentScreenBackground?: string;
  enrollmentScreenBackgroundPosition?: string;

  programActivitiesShownFirst?: boolean;
  showMemberPersonalizations?: boolean;

  player?: {}; // preparing for upgrade player configuration

  solutions?: {}[];

  defaultIcon?: string;
  personalizationSectionLabels?: string;
  
  activityVerb?: string;
  activityVerbPlural?: string;
  
  showFavoritesEvenIfEmpty?: boolean;
  closeParentPopover?: boolean;
  
  collapsableSections?: boolean;
  collapsableSectionsShowCount?: boolean;
  collapseSectionsByDefault?: boolean;
  
  // NEED TO BE ORGINIZE (fixing eslint errors)
  sectionTitlesShowing?: boolean;
  sectionTitleForPrograms?: string;
  showSpecialistSection?: boolean;
  sectionTitleForYourSpecialist?: string;
  sectionTitleForPersonalization?: string;
  invertIconsInDarkMode?: boolean;
  biometricsIcon?: string;
  enrollmentScreenBackgroundBrightness?;
  playerAllowAddHabitAndActivities?: boolean;
  playerShowAddButton?: boolean;
  playerShowTrackablesFab?: boolean;
  
  // NEW (since 1.10.4)
  percentOff100BypassStripePaymentDuringEnrollment?: boolean; // Trying to bypass unecessary $0 Stripe Payments if coupon code is 100% off
  motivationalQuote?: string;
  hideVersionInPlayer?: boolean;
  timesSaved?: number;
}

@Injectable()
export class AcademyService {

  environment = environment;

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    public loadingController: LoadingController,
  ) {
    // console.log('%c Authorizing user', defaults.styles.authorizing);
    afAuth.authState.subscribe((auth) => {
      if (auth) {
        this.userId = auth.uid;
        // console.log('%c Authorized User id:', defaults.styles.authorized, this.userId);
        this.init();
      }
    });
  }

  id: string;
  userId: string;

  public docRef: DocumentReference;

  private academiesCollection: AngularFirestoreCollection<Academy>;
  private academies: Observable<Academy[]>;

  private academy = new BehaviorSubject(null);

  public static getDefaultAcademy(uid): Academy {
    let academy: any;
    academy = {
      id: uid, // should this be set to this?
      uid, // userId ???

      // BRANDING
      logo: defaults.images.logo,
      logoDark: defaults.images.logoDark,
      bgColor: '#fff',
      colorPrimary: '#1F2EE0',
      image: defaults.images.featuredImage,

      // SITE NAME
      name: '', // Your Site Name
      address: '', // your-site-name
      slug: '',

      // SITE HEADER (OLD??)
      // siteHeader: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_sbc%2Fsonny_brown_banner.jpg?alt=media&token=9005420b-bf8e-4ed8-849e-f4ab4f797294',
      // siteHeaderText: '',
      // siteSubHeaderText: '',

      featuredIn: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_sbc%2FfeaturedIn.png?alt=media&token=69cd1628-64fd-4ce2-bcba-887e1dd965c5',

      // NAVIGATION
      // navigation,

      // HERO BANNER
      heroBanner: AcademyService.getDefaultHeroBanner(),

      // FEATURES
      features: AcademyService.getDefaultFeatures(),

      // TESTIMONIAL
      testimonial: AcademyService.getDefaultTestimonial(),

      // ANALYTICS
      reads: 0,
      totalEarned: 0,
      totalSpent: 0,

      // COACH
      coachAvatar: defaults.images.coachAvatar,
      coachTitle: 'About Sonny',
      coachSummary: 'Fitness has provided me a lifestyle that I used to dream of while connecting me with like minded people from all over the world. I live and breath health and fitness and my truest passion is helping others reach their personal peak\'s in all elements of life.',
      coachQuote: 'The blueprint is simple; find something you are passionate about and don\'t look back.',
      youTubeVideoId: 'tB1Y6BLutPM',

      // PROGRAMS
      programHeadline: 'Reshape your life from anywhere in the world with online coaching',

      // BANNER
      bannerTitle: 'LIFESTYLE RETREAT',
      bannerSubTitle: 'KOH SAMUI, THAILAND',
      bannerSubSubTitle: 'Fitness. Mindset. Adventure. Retreat.',
      bannerImage: defaults.images.bannerImage,
      bannerCTA: 'Learn more',

      // CONTACT

      // SOCIAL

      // CLOSING STATEMENT
      closingStatement: 'AUSTRALIA\'S #1 PUBLISHED FITNESS MODEL',
      closingImage: defaults.images.closingImage,

      // CLOSING STATEMENT BANNER
      closingBanner: this.getDefaultClosingBanner(),

      // PAYMENTS
      currencyDefault: 'usd',

      // COUPONS
      couponsEnabled: false, // as of 0.6.4 coupons not fully implemented (only partially working at only a 100% discount to waive all fees for pay programs, and have to be manually set in firestore)

      // PREVIEW (demo mode)
      noSubscriptionLimitedViewEnabled: true,
      numberOfHabitsInDemo: 3,

      // PLAYER BRANDING
      playerColorBg: '#e7e7e4',
      playerColorBgDarkMode: '#121212',
      playerColorPrimary: '#1F2EE0', // copy of colorPrimary
      playerColorPrimaryContrast: '#FFF',
      playerColorLight: '#CCC',
      playerColorMedium: '#777',
      playerColorDark: '#222',
      playerColorSuccess: defaults.colors.success,
      playerColorWarn: defaults.colors.warn,
      playerColorDanger: defaults.colors.danger,

      // playerCardLightMode
      // playerCardDarkMode
      // playerBorderRadius

      // BUTTONS
      buttonFill: 'solid', // solid, outline, clear

      // ONBOARDING
      // onboardingSkipQuestions: true,
      customQuestionsEnabled: true,
      faqs: [],
      onboardingSlides: AcademyService.getOnboardingSlides(),
      eventsBanner: AcademyService.getDefaultEvents(),
      questions: AcademyService.getDefaultQuestions(),
      notifications: AcademyService.getDefaultNotifications(),
      equipment: AcademyService.getDefaultEquipment(),
      goals: AcademyService.getDefaultGoals(),

      // presentAlertIfOnboardingNotCompleted: true,
      // presentToastIfOnboardingNotCompleted: false,
      
      // PLAYER LOOK
      overlapMastheadWithHabitFeed: true,
      invertIconsInDarkMode: true,

      // COMPONENTS
      showStories: true,
      showReadinessScore: false,
      showProgressBar: false,
      showReportCard: false,
      showConsecutiveDays: false,
      showMessagingIcon: true,
      showMotivationalQuote: true,

      // STORIES
      autoplayVideos: true,
      autoplayAudio: false,

      // REPORT
      reportActivityTypeReport: true,
      reportShowExperiencePoints: true,
      reportShowActivityCompliance: true,
      reportShowBiometricCompliance: true,
      reportShowActivityHistory: true,
      reportShowBiometricHistory: true,
      reportShowWorkoutHistory: false,
      reportShowNotes: true,

      // REPORT CARD
      reportCardTitle: "Today's Progress",
      reportCardSubTitle: "Click for a deeper look at your journey",

      // TEMPLATES

      // PROGRAM
      showAcademyNameInMasthead: true,
      hideProgramTileInMastHead: false,

      // ALERT (onboarding not completed)
      presentAlertIfOnboardingNotCompleted: true,
      onboardingNotCompletedAlertName: "Complete your onboarding",
      onboardingNotCompletedAlertDescription: "Set when you want to get notified to move and create a more personalized experience.",
      onboardingNotCompletedAlertColor: "primary",
      // TOAST (onboarding not completed)
      presentToastIfOnboardingNotCompleted: false,
      onboardingNotCompletedToastName: "Onboarding not completed",
      onboardingNotCompletedToastButton: "Finish Onboarding",
      onboardingNotCompletedToastColor: "primary",

      // ALERT (questions not completed)
      presentAlertIfOnboardingQuestionsNotCompleted: true,
      onboardingQuestionsNotCompletedAlertName: "Complete your Profile",
      onboardingQuestionsNotCompletedAlertDescription: "To get a more personalized experience, we recommend that you complete your profile.",
      onboardingQuestionsNotCompletedAlertColor: "primary",
      // TOAST (questions not completed)
      presentToastIfOnboardingQuestionsNotCompleted: false,
      onboardingQuestionsNotCompletedToastName: "Get a more personalized experience.",
      onboardingQuestionsNotCompletedToastButton: "Complete Your Profile",
      onboardingQuestionsNotCompletedToastColor: "primary",
      
      // ALERT (no subscription)
      noSubscriptionShowAlert: false,
      noSubscriptionAlertName: "Subscribe for full access",
      noSubscriptionAlertDescription: "Join today and enjoy a personalized experience",
      noSubscriptionAlertButton: "Add Payment",
      noSubscriptionAlertURL: HelpersService.getDomain() + "/complete-payment",

      // TOAST (no subscription)
      noSubscriptionToastEnabled: true,
      noSubscriptionToastMessage: "Demo Only",


      // EMAIL TEMPLATES
      welcomeEmail: AcademyService.getWelcomeEmailTemplate(),
      enrollmentEmail: AcademyService.getEnrollmentEmailTemplate(),
      inactivityEmail: AcademyService.getInactivityReminderEmailTemplate(),

      // EMAIL ACTIVE SETTING
      sendMemberWelcomeEmail: true,
      sendEnrollmentEmail: true,
      sendInactivityEmail: true,

      // ENROLLMENT BACKGROUND
      enrollmentScreenBackground: defaults.images.featuredImage,
      enrollmentScreenBackgroundPosition: "center",

      // PLAYER
      player: {
        startupRoute: "play",
        registerRoute: "register",
        enrollRoute: "programs",
        showRegisterButton: true,
        showAddToFavoritesButton: false,
      }, // preparing for upgrade player configuration

      // FAB BUTTONS
      playerShowAddButton: true,
      showTrackablesFab: true,

      activityVerb: "Activity",
      activityVerbPlural: "Activities",

    };

    // RETURN
    return academy;
  }

  public static getOnboardingSlides() {
    const image = ""; // Placeholder for an illustration
    const backgroundImage = defaults.images.featuredImage;
    const textColor = "#FFF";
    const backgroundOverlayColor = "#00000099";

    return [
      {
        name: 'Start Your Journey',
        description: 'Get ready to explore new habits and routines that will enhance your daily life!',
        image,
        backgroundImage,
        textColor,
        backgroundOverlayColor,
      },
      {
        name: 'Discover Your Potential',
        description: 'Dive into activities and routines tailored just for you to grow, learn, and thrive.',
        image,
        backgroundImage,
        textColor,
        backgroundOverlayColor,
      },
      {
        name: 'Reflect and Improve',
        description: "End your day with mindful reflections and set intentions for tomorrow's progress.",
        image,
        backgroundImage,
        textColor,
        backgroundOverlayColor,
      },
    ];
  }  

  public static getWelcomeEmailTemplate() {
    return `Hello and welcome to {{academy}}.

Break up sitting, have a movement break to improve your health.  So set your notifications and let's get moving.

We are so happy you are here as we roll out our new platform.  This is our BETA version of the platform and you will see new features pop up from time to time.  Always feel free to provide feedback as we are building this for you!

Some best practices:

  1.  Be sure to set your notifications when you first sign up.
  2.  Try to move at least 3-5 times each day
  3.  Bookmark our page for easy LOGIN if you miss your notifications
  4.  make sure notifications are turned on for your browser.

{{playerLink}}

Thank you so much for joining us here to help make the world a healthier place.

Kindly,
{{coach}}`
  }

  public static getEnrollmentEmailTemplate() {
    return `You've been Enrolled into {{program}} program.

Thank you so much for joining us here to help make the world a healthier place.

{{playerLink}}

Kindly,
{{coach}}`
  }

  public static getInactivityReminderEmailTemplate() {
    return `Hello {{student}}!.

It's been {{lastActive}} since you've taken care of your human.

So come back for some micro-dosing movement for your health.

{{playerLink}}

You'll be glad you did it.

See you soon,
{{coach}} team`
  }

  public static getDefaultEquipment() {
    return [
      {
        name: 'Dumbbell',
        slug: 'dumbbell',
        image: '/assets/equipment/stairs.svg',
        enabled: false,
        description: 'Burn fat & get lean',
      },
      {
        name: 'Leber Fitness',
        slug: 'leber-fitness',
        image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/userMedia%2F3nkS8sO6v2WpJh4EEiFAz94KxEJ2%2F1640066489649_Lebert%20Equalizer.jpeg?alt=media&token=96f7927a-4d4d-477c-a1a1-1faca004fd4c',
        enabled: false,
        description: 'Burn fat & get lean',
      },
      {
        name: 'Hyperwear',
        slug: 'hyperwear',
        image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/userMedia%2F3nkS8sO6v2WpJh4EEiFAz94KxEJ2%2F1640140104791_Hyperwear%20Sandbell%20Pro.jpeg?alt=media&token=d4d85ddb-ac25-4419-b10e-99128e06f777',
        enabled: false,
        description: 'Burn fat & get lean',
      },
      {
        name: 'Stairs',
        slug: 'stairs',
        image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/freakyStorage%2F1600062126017_stairs.jpeg?alt=media&token=9b1ebe9c-f985-40d9-b29a-ab58ee96dfd1',
        enabled: false,
        description: 'Full Body Workout fitness dip stand',
      },
      {
        name: 'Steps',
        slug: 'steps',
        image: '/assets/equipment/steps.svg',
        enabled: false,
        description: 'Tone up & feel healthy with this versatile tool',
      },
      {
        name: 'Equipment Free',
        slug: 'equipment-free',
        image: '/assets/equipment/stairs.svg',
        enabled: true,
        description: 'Body weight exercises you can do anywhere',
      },
    ];
  }

  public static getDefaultGoals() {
    return [
      {
        name: 'Improve Wellness',
        description: 'Focus on holistic health, mindfulness, and balance.',
      },
      {
        name: 'Boost Productivity',
        description: 'Develop habits to enhance focus and efficiency.',
      },
      {
        name: 'Learn a New Skill',
        description: 'Dedicate time to mastering something new.',
      },
      {
        name: 'Increase Physical Fitness',
        description: 'Build strength, endurance, and overall fitness.',
      },
      {
        name: 'Enhance Creativity',
        description: 'Unlock your creative potential through daily exercises.',
      },
    ];
  }  

  public static getDefaultSpecialists() {
    return [
      {
        name: 'Dr. Taylor Reed',
        description: `Taylor is an expert in personal development and habit formation, helping individuals transform their routines.`,
        image: '',
        linkURL: '',
        userId: '',
      },
      {
        name: 'Avery Bennett',
        description: `Avery specializes in wellness coaching, meditation, and stress management for a balanced lifestyle.`,
        image: '',
        linkURL: '',
        userId: '',
      },
    ];
  }  

  public static getDefaultNotifications() {
    return [
      {
        name: 'Morning Motivation',
        description: 'Start your day with a positive mindset.',
        message: "Here's your morning boost: {{playerLink}}",
        hour: 7,
        minute: 0,
        enabled: true,
      },
      {
        name: 'Midday Break',
        description: 'Take a moment to recharge and refocus.',
        message: 'Take a break and refresh yourself: {{playerLink}}',
        hour: 12,
        minute: 0,
        enabled: false,
      },
      {
        name: 'Evening Reflection',
        description: 'Unwind with a mindful evening routine.',
        message: 'Wind down with this reflection: {{playerLink}}',
        hour: 19,
        minute: 0,
        enabled: true,
      },
      {
        name: 'Nighttime Relaxation',
        description: 'Prepare for a restful sleep with relaxing movements.',
        message: 'Relax and prepare for rest: {{playerLink}}',
        hour: 21,
        minute: 0,
        enabled: false,
      },
    ];
  }  

/*
ChatGP suggested notifications

BROTHER:
Unlock your full squat potential and say goodbye to back pain by adding mobility exercises to your routine - your body will thank you!

FATHER:
Experience the freedom and ease of movement you once had by incorporating mobility work into your day - your body will feel stronger and more agile!

BROTHER (shorter):
Improve your squat and alleviate back pain with mobility exercises - your body will thank you for the extra care.

FATHER (shorter):
Enhance your movement and feel stronger with mobility work - your body deserves it.

BROTHER (punchier):
Don't let back pain hold you back - power up your squat with mobility exercises and feel unstoppable!

FATHER (punchier):
Unleash your body's full potential and take on the world with confidence - mobility work can help you get there!

ACTION:
Don't forget to set aside just 10 minutes each day for mobility work.

*/

public static getDefaultQuestions() {
  return {
    goal: {
      enabled: true,
      question: "What's your main focus for joining?",
      description: 'Let us know your primary goal so we can personalize your journey.',
    },
    gender: {
      enabled: false,
      question: 'What is your gender?',
      description: '',
    },
    dateOfBirth: {
      enabled: false,
      question: 'What is your date of birth?',
      description: '',
    },
    age: {
      enabled: false,
      question: 'How old are you?',
      description: '',
    },
    height: {
      enabled: false,
      question: 'How tall are you?',
      description: '',
    },
    weight: {
      enabled: false,
      question: 'What is your weight?',
      description: '',
    },
    equipment: {
      enabled: false,
      question: 'Select available equipment',
      description: 'Which equipment do you have access to?',
      betaFeature: true,
    },
    daysOfWeek: {
      enabled: true,
      title: 'Days of Week',
      question: 'Which days would you like to dedicate to your journey?',
      description: 'Set the days you want to receive notifications and stay on track!',
    },
    notifications: {
      enabled: true,
      question: 'How many reminders would you like per day?',
      description: 'Set up notifications to keep you motivated and consistent.',
    },
    categoriesOfFocus: {
      enabled: false,
      question: 'Select a category of focus',
      description: "Pick areas you'd like to focus on, such as wellness or productivity.",
      betaFeature: true,
    },
    solution: {
      enabled: false,
      question: 'Select a solution',
      description: "What's a common challenge you'd like help with?",
      betaFeature: true,
    },
    activity: {
      enabled: false,
      question: 'Select an activity',
      description: 'What activities are you most interested in?',
      betaFeature: true,
    },
    program: {
      enabled: false,
      question: 'Select a program',
      description: 'Which program interests you the most?',
      betaFeature: true,
    },
    specialist: {
      enabled: false,
      question: 'Select a specialist',
      description: "Is there a specialist you'd like to connect with?",
      betaFeature: true,
    },
    finished: {
      enabled: true,
      question: 'All set!',
      description: "We're excited to get started with your personalized program!",
    },
  };
}

  





  public static getDefaultNavigation() {
    return {
      logo: defaults.images.logo,
      items: [
        {
          title: 'Programs',
          url: '/programs/##academyId##',
        },
        {
          title: '',
          url: '',
        },
        {
          title: '',
          url: '',
        },
        {
          title: 'Log in',
          url: '/login',
        },
      ]
    };
  }

  public static getDefaultHeroBanner() {
    return {
      background: {
        image: defaults.images.featuredImage,
        size: 'cover',
        position: 'center',
        repeat: 'no-repeat',
        textColor: '#000',
        textShadow: 'none',
        overlayColor: '#000',
        overlayOpacity: 0,
      },
      content: {
        title: 'Welcome to the Academy',
        subtitle: 'Get ready to upgrade your human',
        subsubtitle: '',
      },
      cta: [
        {
          name: 'Get Coaching',
          url: '',
          fill: 'solid'
        },
        {
          name: 'Lifestyle Retreats',
          url: '',
          fill: 'outline'
        },
      ]
    };
  }

  public static getDefaultFeatures() {
    return {
      background: {
        image: defaults.images.featuredImage,
        size: 'cover',
        position: 'center',
        repeat: 'no-repeat',
        textColor: '#000',
        textShadow: 'none',
        overlayColor: '#000',
        overlayOpacity: 0,
      },
      content: {
        title: 'Your exercise vitamin',
        subtitle: 'Transform the way you exercise and make it:',
        content: '',
      },
      items: [
        {
          title: 'Consent',
          content: 'When it comes to long-term health consistency trumps intensity. Seven Movements makes building a new exercise routine easy.',
          img: '',
        },
        {
          title: 'Effective',
          content: 'Seven Movements is programmed to make you a better mover.  Combining the science of functional movement & recovery you will feel the difference just a few minutes can make.',
          img: '',
        },
        {
          title: 'Sustainable',
          content: 'Four 7-minute micro-doses of movement every day is all it takes to hit your recommended exercise targets. Just like your multi-vitamin, take your movement micro-doses every day for optimal results.',
          img: '',
        }
      ],
      cta: [{
          name: 'Get Coaching',
          url: '',
          fill: 'solid'
        }]
    };
  }


  public static getDefaultIntro() {
    return {
      content: {
        title: 'We are not moving enough',
        subtitle: '4 out of 5 adults are not hitting recommended exercise targets required for long-term health.',
        content: 'The no pain, no gain approach is not a long-term solution. Unsustainable exercise programs focused on short-term results have become the norm. It is no wonder why it so difficult to stick to an exercise routine.',
      },
      cta: {
        name: 'Start today!',
        link: '/enroll',
        fill: 'solid'
      },
      image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_sbc%2Fsonny_brown_banner_no_text.jpg?alt=media&token=9fea4cdb-3f82-4063-b86a-88a7e5fa13a9',
    };
  }


  public static getDefaultBenefits() {
    return {
      content: {
        title: 'This is the Seven Movements Difference',
        subtitle: 'Learn how to micro-dose your movement through the day in 7-minute chunks. The goal is long-term health.  Consistent habits and regular routines are how we get there.',
        content: 'Many exercise programs are built around complexity and intensity which makes them unsustainable.  Seven Movements is the alternative to that. Simply take your movement dose each day to hit your baseline fundamental movement needs.  These can help you supplement your current activities or build a new movement habit.',
      },
      image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_sbc%2Fsonny_brown_banner_no_text.jpg?alt=media&token=9fea4cdb-3f82-4063-b86a-88a7e5fa13a9',
    };
  }

  public static getDefaultClosingBanner() {
    return {
      background: {
        image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_bad_boy_academy%2FNAK29oQTk6A6telSKB6A_desk.jpg?alt=media&token=68567ea6-9a34-44bf-9a14-659bea137cfa',
        size: 'cover',
        position: 'center',
        repeat: 'no-repeat',
        textColor: '#000',
        textShadow: 'none',
        overlayColor: '#000',
        overlayOpacity: 0,
      },
      content: {
        title: 'Start the Academy Now',
        subtitle: 'Don\'t wait a moment more... get access to all best courses',
        subsubtitle: '',
      },
      cta: [
        {
          name: 'Enroll in Academy Now',
          url: '',
          fill: 'solid'
        },
        {
          name: 'Lifestyle Retreats',
          url: '',
          fill: 'outline'
        },
      ]
    };
  }

  public static getDefaultTestimonial() {
    return {
      content: 'I started working with ' + defaults.platform.name + ' on my fitness, nutrition, but most important, on my confidence and attitude to return as a better version of who I was before.',
      author: 'Max Power - The Simpsons',
      image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_sbc%2Ftestimonial%20image.jpg?alt=media&token=2f3db5a5-32a4-4047-aec9-dc145c3a2a97',
    };
  }

  public static getDefaultEvents() {
    return {
      headline: 'Join Our Training Programs',
      background: {
        image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_sbc%2FclosingImage.jpg?alt=media&token=057223c5-f46c-452e-b7b7-9b2df5ed2ada',
        size: 'contain',
        position: 'right',
        repeat: 'no-repeat',
      },
      events: [
        {
          dateStart: '01',
          dateEnd: '08',
          month: 'August',
          name: 'Summer Camp',
          location: 'Split, Croatia',
          description: '7 days basic training',
          type: 'Basic training',
          image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_bad_boy_academy%2Fzrce.jpg?alt=media&token=e2522009-ef3c-440c-b703-9bb261e73d68',
          note: 'Event has started',
        },
        {
          dateStart: '9',
          dateEnd: '16',
          month: 'August',
          name: '9\'s and 10\'s Training',
          location: '9\'s and 10\'s Camp',
          description: '7 days advanced training',
          type: 'Advanced training',
          image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_bad_boy_academy%2F10.jpg?alt=media&token=72e054b0-cbe3-4fa4-abb6-12cff9036db7',
        },
        {
          dateStart: '23',
          dateEnd: '30',
          month: 'August',
          name: 'Summer Camp',
          location: 'Barcelona, Spain',
          description: '7 days advanced training',
          type: 'Advanced training',
          image: 'https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/test_bad_boy_academy%2Fbrc.jpg?alt=media&token=4fc4249f-4c94-4d2a-9f47-d55443753749',
        }
      ],
    };
  }

  init(collection = 'academy') {
    // console.log('%c Initialize Academies Service. Collection Set to: ', defaults.styles.coder, collection);
    this.academiesCollection = this.db.collection<Academy>(collection);
    this.academies = this.academiesCollection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
      ,first());
  }

  getItems() {
    console.log('%c Getting a list of all Academies... Should we be doing that right now?', defaults.styles.warn);
    return this.academies;
  }

  getItem(id, collection?: string) {
    if (!this.academiesCollection || collection) this.init(collection);
    return this.academiesCollection.doc<Academy>(id).valueChanges();
  }

  updateItem(academy: Academy, id: string) {
    return this.db.collection('academy').doc(id).update(academy);
  }

  set(academy: Academy, academyId?: string) {
    if (environment.verboseLogs) console.log('%c Saving Academy via Set: ' + academyId, defaults.styles.loading, academy);
    if (!academyId) {
      console.log('%c Set Academy without providing academyId. Setting Academy of authenticated user. Could be problematic if managing external Academy.', defaults.styles.warn, this.userId);
      academyId = this.userId
    }
    return this.academiesCollection.doc(academyId).set(academy, { merge: true }).then((res) => {
      console.log('%c Academy updated.', defaults.styles.written, this.academy);
    })
  }



  // helper
  getPublicAcademy(id: string) {
    return this.getItem(id, "academy-published");
  }











  // TODO: WHY USE THIS COMPLICATED FUNCTION, WHEN getItem(id) is sufficient.
  getAcademyById(academyId?: string, collection = 'academy-published', that?: any): Observable<Academy> {
    let id = (academyId) ? academyId : this.userId;
    if (!id) {
      console.error('%c Academy ID can not be empty.', defaults.styles.error);
      return;
    }

    this.docRef = this.db.firestore.doc(collection +  "/" + id);

    let data;
    if (environment.verboseLogs) console.log('%c Academy loading...', defaults.styles.loading, {academyId, id, collection});
    this.docRef.get().then((doc) => {
      if (doc.exists) {
        data = doc.data() as Academy;
        console.log('%c Academy returned', defaults.styles.loaded, data);
        if (!data.id) {
          data.id = id;
          console.log('%c Academy id not attached to object. Adding now, but this might not be the best idea.', defaults.styles.heal, id);
        }
        this.academy.next(data);
        // this.academy.complete();
      } else {
        console.log('%c Academy not found...', defaults.styles.error, id);
        this.academy.next(null);
        if (that) {
          that.loadingPrograms = false;
          that.initDefaultAcademy();
        }
        // this.academy.complete();
      }
    }).catch((error) => {
      console.error('%c Error getting Academy:', defaults.styles.error, error);
      // this.academy.complete();
    });

    return this.academy.asObservable();
  }

  getMemberByUserId(userId: string, academyId: string): Observable<Member | null> {
    // Validate the inputs
    if (!userId || !academyId) {
      console.error('User ID and Academy ID must not be empty');
      return from(null); // Immediately return an Observable with null for consistency
    }

    // Construct the path to the specific member in the sub-collection
    const memberPath = `academy/${academyId}/members/${userId}`;

    // Convert the Promise returned by 'get' into an Observable using 'from'
    return from(this.db.firestore.doc(memberPath).get()).pipe(
      map(doc => {
        if (doc.exists) {
          // If the document exists, return the data cast to a Member
          return doc.data() as Member;
        } else {
          // If the document doesn't exist, log and return null
          console.log('No such member!');
          return null;
        }
      }),
      catchError(error => {
        // In case of an error, log and return an Observable with null
        console.error('Error getting member:', error);
        return from(null); // 'of' is used to return an Observable with a specific value
      })
    );
  }
  getPublicAcademies(limit = 20): Observable<any> {
    const documents = this.db.collection<Academy>(
      'academy-published',
      ref => ref.where('isPublic', '==', true)
      .limit(limit)
    ).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          const docId = a.payload.doc.id; // Duplicated to fix conflict with id
          return { id, docId, ...data } as Academy;
        });
      })
      , first()); // What about using  take(1)? I still don't understand the diff // does this even do anything?
    return documents;
  }





  async saveAcademy(academy) {
    console.log('%c Set Academy without providing academyId. Setting Academy of authenticated user. Could be problematic if managing external Academy.', defaults.styles.depreciated, this.userId);
    if (environment.verboseLogs) console.log('%c Saving academy settings', defaults.styles.writing, academy);
    academy.updatedAtLocal = Date.now();
    this.set(academy).then(() => {
      console.log('%c Aademy settings saved', defaults.styles.written, academy);
    });
  }

















  getAcademyDays(id?: string, cohort?: string, limit = 30): Observable<any> {
    id = (id) ? id : this.userId;
    let collection = `/academy/${id}/days/`;
    if (cohort && cohort.length > 0) collection = `/academy/${id}/cohort/${cohort}/days/`;
    if (cohort && cohort.length > 0) console.log('%c Cohort data not returned due to orderBy issue that remains unsolved.', defaults.styles.error); // TODO: TEMP ERROR
    if (environment.verboseLogs) console.log('Get Academy last days:', limit);
    if (environment.verboseLogs) console.log("collection", collection);
    const documents = this.db.collection<Academy>(
      collection,
      ref => ref
      .orderBy('updatedAt', 'desc') // TODO: causing issues, we need a timestamp for start of day startOfDayStamp
      .limit(limit)
    ).snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data } as Academy;
        });
      }));
    return documents;
  }


  getAcademyYearStats(id?: string, year?: number) {
    id = (id) ? id : this.userId;
    const now = new Date;
    year = (year) ? year : now.getFullYear();

    if (environment.verboseLogs) console.log('Get Academy Stats for Year from collection: ' + `/academy/${id}/years/`, year);

    const collection = this.db.collection<any>(`/academy/${id}/years/`);
    return collection.doc<any>(year.toString()).valueChanges();
  }


  getAcademyMonthStats(id?: string, month?: number, year?: number) {
    id = (id) ? id : this.userId;
    const now = new Date;
    month = (month) ? month : now.getFullYear();
    year = (year) ? year : now.getFullYear();

    if (environment.verboseLogs) console.log('Get Academy Stats for Year from collection: ' + `/academy/${id}/months/`, month);

    const collection = this.db.collection<any>(`/academy/${id}/years/${year}/months/`);
    return collection.doc<any>(month.toString()).valueChanges();
  }














  // STYLES
  setStyle(property: string, value: string): void {
    const elementRef = this.elementRef;
    if (!elementRef?.nativeElement) { 
      console.log("%c Failed to set style. Element reference missing.", defaults.styles.heal, {property, value});
      return;
    }
    elementRef.nativeElement.style.setProperty(property, value);
    // console.log("Set Style", {property, value});
  }

  elementRef;
  setAllStyles(elementRef, academy) {
    if (!academy) {
      console.log('%c Academy not found. Colors not set.', defaults.styles.warn);
      return;
    }
    this.elementRef = elementRef;

    const styles = [
      // player
      ['--player-color-bg', academy.playerColorBg],
      ['--player-color-bg-darkMode', academy.playerColorBgDarkMode ?? "#272727"],
      ['--player-color-bg-rgb', HelpersService.convertHexToRGB(academy.playerColorBg)],
      ['--player-color-primary', academy.playerColorPrimary],
      ['--player-color-primary-contrast', academy?.playerColorPrimaryContrast],
      ['--player-color-light', academy.playerColorLight],
      ['--player-color-medium', academy.playerColorMedium],
      ['--player-color-dark', academy.playerColorDark],
      ['--player-color-success', academy.playerColorSuccess],
      ['--player-color-warn', academy.playerColorWarn],
      ['--player-color-danger', academy.playerColorDanger],
      ['--player-card-lightMode', academy.playerCardLightMode],
      ['--player-card-darkMode', academy.playerCardDarkMode ?? "#121212"],
      ['--player-border-radius', academy.playerBorderRadius],
      // academy
      ['--academy-color-bg', academy?.bgColor ],
      ['--academy-color-primary', academy?.colorPrimary ],
      ['--academy-hero-text-color', academy?.heroBanner?.background?.textColor],
      ['--academy-hero-text-shadow', academy?.heroBanner?.background?.textShadow],
      ['--academy-hero-overlay-color', academy?.heroBanner?.background?.overlayColor],
      ['--academy-hero-overlay-opacity', academy?.heroBanner?.background?.overlayOpacity],
      // other (consider using more agnostic color labels, and not specific to a component or element)
      ['--enrollment-screen-background-color', academy?.enrollmentScreenBackgroundColor],
      ['--bullet-background-active', academy.playerColorPrimary],
    ];

    let errorLogOutput: any = [];
    let logOutput: any = [];
    styles.forEach(element => {
      const variable = element[0];
      const value = element[1];
      if (variable && value) {
        this.setStyle(variable, value);
        logOutput.push({variable, value});
      } else {
        errorLogOutput.push(variable);
      }
    });
    // console.log("%c Styles set", defaults.styles.processed, logOutput);
    if (errorLogOutput?.length > 0) {
      console.log("%c Failed to set some styles", defaults.styles.heal, errorLogOutput);
    }
  }





}
