import { defaults } from 'src/constants/constants';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { map, first } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  private collection: AngularFirestoreCollection<any>;
  private documents: Observable<any[]>;

  userId;

  platformManifestSubscription: Subscription;

  private manifestSubject = new BehaviorSubject<any>(null); // BehaviorSubject to hold the project manifest
  manifest$: Observable<any> = this.manifestSubject.asObservable(); // Expose the Observable to allow other components to subscribe

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    private toastController: ToastController,
  ) {
    this.init();
    // console.log('%c Authorizing user', defaults.styles.authorizing);
    // afAuth.authState.subscribe((auth) => {
    //   if (auth) {
    //     this.userId = auth.uid;
    //     console.log('%c Authorized User id:', defaults.styles.authorized, this.userId);
    //     // this.init();
    //   } else {
    //     console.error('User not authenticated.');
    //   }
    // });
  }

  init() {
    // console.log("%c Platform Service Initializing", defaults.styles.component);
    this.collection = this.db.collection<any>('_platform');
    this.documents = this.collection.snapshotChanges().pipe(
      map(actions => {
        return actions.map(a => {
          const data = a.payload.doc.data();
          const id = a.payload.doc.id;
          return { id, ...data };
        });
      })
    );
  }

  // getItems() {
  //   return this.documents;
  // }

  getItem(id) {
    if (!id) {
      console.error('Platform Document ID can not be NULL. Aborting.');
      return;
    }
    return this.collection.doc<any>(id).valueChanges();
  }

  updateItem(doc: any, id: string) {
    return this.collection.doc(id).update(doc);
  }

  // addItem(doc: any) {
  //   return this.collection.add(doc);
  // }

  setItem(item: any, id) {
    if (!item || !id) {
      console.log('%c Failed to set Item. Missing item and/or id.', defaults.styles.error, {item, id});
      return;
    }
    return this.collection.doc(id).set(item, {merge: true});
  }

  // removeItem(id) {
  //   return this.collection.doc(id).delete();
  // }










  loadPlatformManifest() {
    console.log("%c Platform manifest loading", defaults.styles.acmeLoading);

      // Unsubscribe from the existing subscription if it exists
    if (this.platformManifestSubscription) {
      this.platformManifestSubscription.unsubscribe();
    }

    this.platformManifestSubscription = this.getItem('manifest-public').subscribe(doc => {
      if (doc) {
        // Update the BehaviorSubject with the document data
        console.log('%c Platform Manifest loaded', defaults.styles.loaded, doc);
        this.manifestSubject.next(doc);
      } else {
        console.error('Platform Manifest Error -> No such document!');
      }
    });
  }

  // Get the current config value
  getManifest() {
    return this.manifestSubject.value;
  }

  async presentToast(message, color = 'dark') {
    if (!message) {
      return;
    }
    const toast = await this.toastController.create({
      message,
      color,
      duration: defaults.toast.longDelay,
    });
    toast.present();
  }

}
