import { defaults } from 'src/constants/constants';
import { User } from 'src/services/users.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject, from } from 'rxjs';
import { switchMap, first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { EnrollService } from './enroll.service';
import { resolve } from 'dns';
import { rejects } from 'assert';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private router: Router,
    private enrollService: EnrollService,
  ) {
    this.user = this.afAuth.authState.pipe(
      switchMap(user => {
        if (user) {
          return this.afs.collection('users').doc<User>(user.uid).valueChanges();
        } else {
          return of(null);
        }
      })
    );
  }

  public uid: string;
  private accountAuthorized = new BehaviorSubject(false);

  private _isUserAuthenticated = new BehaviorSubject(null);

  // ///////////////////////////////////////////////
  // HACK-IE WAY TO SENT BACK TO PARENT (rethink this)
  public that; // calling page for errors (dirty hack in place of a good solution)

  // Trying to create an user observable
  private user: Observable<User>;

  public static isGod(userId) {
    console.log('%c NOT SECURE WAY OF IMPLEMENTING GOD MODE', defaults.styles.depreciated, userId);
    // WHICH USERS CAN SEE GLOBAL MANAGE MENU ITEMS
    // Not actually giving access to user, just displaying the navigation
    // TODO: needs to be moved to a Firestore and check for a document in a collection "/global-permissions/${userId}"
    // CONFIG: If you want to enable global access for an owner, please add them here
    const gods = [
      "_superAdmin",   // super admin
      "_owner",   // platform owner
      "ugWavqSLcOReqV9KpVcg0Vpz4k53",   // igor
      "l43FCPJgcc9srUGudXri",           // slava
      "3nkS8sO6v2WpJh4EEiFAz94KxEJ2",   // dan
      "OEhOdEFUGEgBJ1bRlb8dH5ktub33",		// ryan (sm?)
      "0R6vY0Jz7lSfvvCdNbkCduFcVUF3",		// ryan (day-in-life-app)
      "dXYxL6WUtEWk7MVbSmaiHIN4oB22",   // abishek?
      "mophnOAqRrMFgPRCNK2Mu7LCgjN2",   // elk-and-owl
    ];

    if (gods.includes(userId)) {
      console.log('%c God mode enabled. User ID valid.', defaults.styles.warn);
      return true;
    } else {
      return false;
    }
  }

  async createAccount(email, password, academyId, programId?, userCredentials?, academy?, program?, referral?): Promise<any> {
    console.log('%c Create account requested for: ' + email, defaults.styles.loading, {email, academyId, programId, academy, program, referral});
    try {
      const credential = await this.afAuth.createUserWithEmailAndPassword(email, password);
      console.log('%c Account Created', defaults.styles.fresh, credential.user);
      this.setUserData(credential.user, academyId, programId, userCredentials, academy, program, referral);
      return Promise.resolve(credential.user);
    } catch (error) {
      console.log('%c ', defaults.styles.error, error.message);
      console.table(error);

      // TODO: Trying to return code via observable, but not working (see code block below)
      if (error.code === 'auth/email-already-in-use') {
        // return await error.message;
      } else if (error.code === 'auth/user-not-found') {
        // auth/user-not-found
      }

      // ///////////////////////////////////////////////
      // HACK-IE WAY TO SENT BACK TO PARENT (rethink this)
      if (this.that) {
        this.that.errorMessage = error.message;
      }
    }

    // return this.accountAuthorized.asObservable();
  }

  async login(email, password) {
    console.log('%c LOGIN -> AUTH REQUEST (with email)', defaults.styles.loading, {email, password});
    if (!email) {
      console.log('%c MISSING EMAIL - Can not login without email.', defaults.styles.error, {email, password});
      return;
    }
    if (!password) {
      console.log('%c MISSING PASSWORD - Can not login without password.', defaults.styles.error, {email, password});
      return;
    }
    try {
      const credential = await this.afAuth.signInWithEmailAndPassword(email, password);
      this.uid = credential.user.uid;
      console.log('%c User logged in', defaults.styles.fresh, this.uid);
      // return await this.updateUserData(credential.user);  // NO NEED, WE ALREADY DID WHEN ACCOUNT WAS CREATED
    } catch (error) {
      console.log('%c LOGIN FAILED -> CODE', defaults.styles.error, error.code);
      console.log('%c LOGIN FAILED -> MESSAGE', defaults.styles.error, error.message);
      console.log('%c LOGIN FAILED -> ERROR', defaults.styles.error, error);
      console.table('%c LOGIN FAILED -> ERROR (in table view)', error);

      if (error.code === 'auth/email-already-in-use') {
        // return await error.message;
      } else if (error.code === 'auth/user-not-found') {
        // auth/user-not-found
      }

      // ///////////////////////////////////////////////
      // HACK-IE WAY TO SENT BACK TO PARENT (rethink this)
      if (this.that) {
        this.that.errorMessage = error.message;
      }
    }
  }

  async logout() {
    await this.afAuth.signOut();
    this.uid = null;
    console.log('User logged out.');
  }

  public setUserData(userCred: User, academyId, programId?, userCredentials?, academy?, program?, referral?) {
    console.log('%c Set user data.', defaults.styles.acme, {userId: userCred.id, email: userCred.email, academyId, programId, referral, userCred, userCredentials});

    if (!academyId) {
      console.log('%c Academy ID missing. User data could not be set.', defaults.styles.error, this.uid);
      return;
    }

    let user:any = {};
    user = { 
      uid: userCred.uid,
      registeredAcademyId: academyId,
      activeAcademyId: academyId, // TODO: Testing v2... Just trying to move enrollment into the cloud functions
      lastActive: Date.now(),
    }

    if (programId) user.registeredProgramId = programId;
    if (programId) user.activeProgramId = programId; // TODO: Testing v2... Just trying to move enrollment into the cloud functions

    // NEW - Since we moved program copy to user collection on to the cloud
    if (academy?.allowMultiplePrograms) {
      console.log('%c Allow multiple programs enabled for user by Academy: ' + academy.allowMultiplePrograms, defaults.styles.fresh, academy);
      user.allowMultiplePrograms = true;
    }

    // Primary program?
    if (program?.primaryProgram) {
      user.primaryProgramId = program.id ?? programId;
      console.log('%c Primary program set to: ' + user.primaryProgramId, defaults.styles.fresh, program);
    }

    // activeProgramLength
    if (program?.weeks?.length) {
      user.activeProgramLength = program.weeks.length;
      console.log('%c Active program length set to: ' + user.activeProgramLength, defaults.styles.fresh, program);
    }

    // ATTACH ACADEMY OBJECT (striped version)
    if (academy) {
      user.activeAcademy = {};
      const academyImageDefaultImage = "https://firebasestorage.googleapis.com/v0/b/hackyourhuman.appspot.com/o/solutions%2Fsolution_empty.png?alt=media&token=9f3c6684-0510-4ad7-b094-ecd6ff8289ca"; // default image
      user.activeAcademy.name = academy.name ?? "No Name Academy";
      user.activeAcademy.image = academy.heroBanner.background.image ?? academyImageDefaultImage;
      user.activeAcademy.slug = academy.slug ?? academyId ?? null;
      console.log('%c Setting activeAcademy in user document from front-end is depreciated and is being set via cloud. Remove this from front-end code.', defaults.styles.depreciated, user.activeAcademy); // TODO: Depreciated way (moved to cloud onCreateUserEnrollmentIntent function already, needs verification)
    }

    // TEST - DEBUGGING STUFF
    // REMOVE FROM HERE, OR FROM ENROLL PAGE FUNCTION
    if (userCredentials) {
      console.log('%cNew User. Lets set name from user credentials.', defaults.styles.loaded, userCredentials);
      if (userCred.firstName || userCred.lastName) {
        console.log('%c New User first and last names , but user credentials already has a name set. User was created via Academy Membership.', defaults.styles.warn, userCred);
      }
      user.firstName = userCredentials.firstName;
      user.lastName = userCredentials.lastName;
    }
    // END TEST

    // REGISTERED VIA APP or WEBSITE?
    if (environment.isNativeApp) {
      console.log("%c REGISTRATION VIA APP (iOS or Android).", defaults.styles.component);
      user.registeredVia = "app";
      user.registeredViaApp = true;
    } else {
      console.log("%c REGISTRATION VIA WEB.", defaults.styles.component);
      user.registeredVia = "web";
      user.registeredViaWeb = true;
    }

    // VERSION
    user.versionAtRegistration = defaults.version;

    if (referral) {
      console.log("%c REFERRAL CODE FOUND. ADDED TO USER.", defaults.styles.acme, referral);
      user.referral = referral;
    }

    // TEST USER FLAG
    const email = userCred.email;
    if (email.includes("@test.com")) {
      console.log("%c TEST USER DETECTED -> Enabled developer details for this user.", defaults.styles.coder);
      user.showBetaFeatures = true;
      user.showDeveloperDetails = true;
    }

    // SET USER DOCUMENT
    this.afs
      .collection('users')
      .doc(userCred.uid)
      .set(user, { merge: true }).then(res=>{
        console.log('%c User data updated.', defaults.styles.success, user);
      });

    // ENROLL INTENT
    // this.enrollService.createEnrollment(userCred.uid, academy, program).then(res=>{
    //   console.log('%c CREATE USER ENROLLMENT -> User enrolled successfully...', defaults.styles.success, res);
    // });
  }














  isUserAuthenticated(redirectToLogin = false) {
    console.log("%cDEPRECIATED -> This could be proplematic implementaotion and should be replaced with afAuth.authState. KISS!", defaults.styles.depreciated);
    console.log('%c User authenticating...', defaults.styles.authorize);
    try {
      this.afAuth.authState.subscribe((auth) => {
        if (auth) {
          const userId = auth.uid;
          console.log('%c User authenticated:', defaults.styles.authorized,  userId);
          this._isUserAuthenticated.next(userId);
        } else {
          console.log('%c User not authenticated.', defaults.styles.error);
          this._isUserAuthenticated.next(null);
          // this._isUserAuthenticated.complete();
          if (redirectToLogin) { this.redirectToLogin(); }
        }
      });
    } catch (error) {
      console.log('%c Authorization Error', defaults.styles.error, error);
      this._isUserAuthenticated.complete();
      if (redirectToLogin) { this.redirectToLogin(); }
    }
    return this._isUserAuthenticated.asObservable();
  }

  redirectToLogin() {
    const navigationExtras = {
      state: {
        error: 'Failed to authorize',
      }
    };
    this.router.navigate(['login'], navigationExtras);
  }
  
  resetPassword(email) {
    return from(this.afAuth.sendPasswordResetEmail(email));
  }
  
  async sendMagicLinkOnEmail(email) {
    console.log('%c', defaults.styles.loading, 'Login requested for: ', email);
    this.that.errorMessage = "";
    this.that.successMessage = "";
    try {
      this.afAuth.fetchSignInMethodsForEmail(email).then(async (signInMethods)=>{
        if(signInMethods.length>0){
          const actionCodeSettings = {
            url: 'http://localhost:4200/login-with-email/'+`?email=${email}`, // The URL where the user will be redirected after clicking the magic link
            handleCodeInApp: true,
          };
          let emResponse =  await this.afAuth.sendSignInLinkToEmail(email,actionCodeSettings);
          this.that.successMessage = "Sign In link has been sent to email address.";
          return emResponse;
          //this.uid = credential.user.uid;

        }else{
          this.that.errorMessage = "User not found.";
        }
      })      

      
    } catch (error) {
      console.log('%c LOGIN FAILED', defaults.styles.error, error.message);
      console.table(error);

      if (error.code === 'auth/email-already-in-use') {
        // return await error.message;
      } else if (error.code === 'auth/user-not-found') {
        // auth/user-not-found
      }

      // ///////////////////////////////////////////////
      // HACK-IE WAY TO SENT BACK TO PARENT (rethink this)
      if (this.that) {
        this.that.errorMessage = error.message;
      }
    }
  }
  checkForMagicLinkValidation(emailLink){
    if(this.afAuth.isSignInWithEmailLink(emailLink)){
      return true;
    }else{
      return false;
    }
  }
  loginWithMagicLink(email,url){
    this.afAuth.signInWithEmailLink(email, url)
    .then((result) => {
      console.log('User signed in:', result.user);
      this.uid = result.user.uid;
    })
    .catch((error) => {
      console.error('Error signing in:', error);
      this.that.errorMessage = error;
    });
  }
  
}
