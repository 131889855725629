import { defaults } from "src/constants/constants";
import { HelpersService } from "src/services/Helpers.service";

let isNegative, verbFuture;

export function calculateDaysAgo(value: any, args?: any): any {
    let ago = '';

    // Check if value is null or undefined and return 'never' if specified in args
    if (value == null) {
        return args?.returnNever ? 'never' : '';
    }

    value = HelpersService.getDate(value);

    if (args && args.verbFuture != null) {
        verbFuture = args.verbFuture;
    }
    if (args && args.verb != null) {
        ago = getDaysAgoForAction(value, args.verb);
    } else {
        ago = daysAgo(value);
    }

    return ago;
}

function getDaysAgoForAction(date, verb) {   
    console.log('getDaysAgoForAction', date, verb); 
    if (isNaN(date)) {
        return 'Never ' + verb.toLowerCase();
    }
    if (isNegative) {
        return 'In ' + daysAgo(date) + ' ago';
    }
    return (verbFuture ? verbFuture + ' ' : verb ? verb + ' ' : '') + daysAgo(date); // + ' ago';
}

function daysAgo(date) {
    const ago = timeSince(new Date(date));
    if (ago === 'just now') {
        return ago;
    }
    if (isNegative) {
        return 'In ' + ago;
    }
    return ago + ' ago';
}


export function timeSince(date: number | Date, isNegative = false): string {
    let seconds = Math.floor((Date.now() - new Date(date).getTime()) / 1000);
    if (seconds === 0) {
        return 'just now';
    }
    if (seconds < 0) {
        isNegative = true;
        seconds = Math.abs(seconds);
    }
    let interval = Math.floor(seconds / 31536000);
    if (interval > 1) {
        return interval + ' years';
    }
    interval = Math.floor(seconds / 2592000);
    if (interval > 1) {
        return interval + ' months';
    }
    interval = Math.floor(seconds / 86400);
    if (interval > 1) {
        return interval + ' days';
    }
    interval = Math.floor(seconds / 3600);
    if (interval > 1) {
        return interval + ' hours';
    }
    interval = Math.floor(seconds / 60);
    if (interval > 1) {
        return interval + ' minutes';
    }
    return Math.floor(seconds) + ' seconds';
}
