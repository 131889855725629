import { defaults } from 'src/constants/constants';
import { ModalController, NavController, Platform, PopoverController, ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable()
export class AppService {

  constructor(
    public navCtrl: NavController,
    public platform: Platform,
    private toastController: ToastController,
    private popoverController: PopoverController,
    private modalController: ModalController,
  ) { }

  public static isApp;
  public version = defaults.version;

  public userId;

  initialize() {
    console.log('This should run first. Initializing application.');
    // this.checkVersion();
  }

  goBack() {
    this.navCtrl.pop();
  }

  navigateForward(url: string) {
    this.navCtrl.navigateForward(url);
  }

  navigateRoot(url: string) {
    this.navCtrl.navigateRoot(url);
  }

  setPlatform() {
    if (this.platform.is('mobileweb')) {
      AppService.isApp = false;
    } else {
      AppService.isApp = true;
    }
    console.log('isApp', AppService.isApp);
  }

  async presentToast(message, color = 'dark') {
    if (!message) {
      return;
    }
    const toast = await this.toastController.create({
      message,
      color,
      duration: defaults.toast.shortDelay,
    });
    toast.present();
  }

  async dismiss(objectToReturn?) {
    if (objectToReturn) console.log('DONE -> Returning data object', objectToReturn);
    try {
      await this.popoverController.dismiss(objectToReturn);
    } catch (popoverError) {
      console.warn('Popover dismissal failed, attempting to dismiss modal...', popoverError);
      try {
        const modal = await this.modalController.getTop();
        if (modal) {
          await this.modalController.dismiss(objectToReturn);
        } else {
          console.error('No modal or popover is active to dismiss.');
        }
      } catch (modalError) {
        console.error('Modal dismissal failed.', modalError);
      }
    }
  }
}
