import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { defaults } from 'src/constants/constants';
import { HelpersService } from './Helpers.service';
import { Router } from '@angular/router';

interface ToastOptions {
  message: string;
  duration?: number;
  position?: 'top' | 'middle' | 'bottom';
  color?: string;
  button?: { text: string; role: string; url?: string; route?: string };
}

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private toastController: ToastController,
    private router: Router,
  ) {}

  toastQueue: ToastOptions[] = [];
  isToastActive = false;

  public async queueToast(input: string | ToastOptions) {
    const toastOptions: ToastOptions = typeof input === 'string' ? { message: input, duration: 2000, position: 'bottom' } : { duration: 2000, position: 'bottom', ...input };
    this.toastQueue.push(toastOptions);
    if (!this.isToastActive) {
      await this.processToastQueue();
    }
  }

  private async processToastQueue() {
    if (this.toastQueue.length === 0) return;
    this.isToastActive = true;

    const toastOptions = this.toastQueue.shift()!;
    const toast = await this.toastController.create(toastOptions);

    if (toastOptions.button) {
      toast.buttons = [
        {
          text: toastOptions.button.text,
          role: toastOptions.button.role,
          handler: () => {
            console.log(toastOptions.button?.text + ' button clicked');
            if (toastOptions.button?.url) {
              HelpersService.openExternalLink(toastOptions.button.url);
            }
            if (toastOptions.button?.route) {
              this.router.navigate([toastOptions.button.route]);
            }
          },
        },
      ];
    }

    await toast.present();

    toast.onDidDismiss().then(() => {
      this.isToastActive = false;
      this.processToastQueue();
    });
  }

  // Wrapper function to maintain compatibility for legacy calls without duplicating logic.
  // Forwards parameters to queueToast() for consistent handling of toast messages.
  public async presentToast(message: string, color = 'dark', position: 'bottom' | 'top' | 'middle' = 'bottom', duration = defaults.toast.shortDelay, button?) {
    this.queueToast({ message, color, position, duration, button });
  }
}
